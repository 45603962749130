<template>
  <!-- 表单区域 -->
  <div class="wrap">
    <div class="facilityBox">
      <el-breadcrumb separator="/" style="margin: 0 0 10px 0">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>客户档案</el-breadcrumb-item>
        <el-breadcrumb-item>代理商信息</el-breadcrumb-item>
      </el-breadcrumb>
      <el-form label-width="150px" :inline="true" disabled>
        <div class="title">代理商认证信息</div>
        <div class="content">
          <div class="upBox">
            <el-image
              class="img"
              :src="confirmInfo.LegalPersonIDCardOnURL"
              :preview-src-list="srcList"
            >
            </el-image>
            <div class="upText">法人身份证(正面)</div>
          </div>
          <div class="upBox">
            <el-image
              class="img"
              :src="confirmInfo.LegalPersonIDCardBackURL"
              :preview-src-list="srcList"
            >
            </el-image>
            <div class="upText">法人身份证(反面)</div>
          </div>
          <div class="upBox" v-if="confirmInfo.BusinessLicenseURL">
            <el-image
              class="img"
              :src="confirmInfo.BusinessLicenseURL"
              :preview-src-list="srcList"
            >
            </el-image>
            <div class="upText">营业执照(原件三合一正本)</div>
          </div>
          <div class="upBox" v-if="confirmInfo.BankCardUrl">
            <el-image
              class="img"
              :src="confirmInfo.BankCardUrl"
              :preview-src-list="srcList"
            >
            </el-image>
            <div class="upText">企业银行卡</div>
          </div>
        </div>
        <div class="content" v-if="eInfoEx">
          <div class="upBox" v-for="(item, index) in eInfoEx" :key="index">
            <el-image
              class="img"
              :src="item.ImageURL"
              :preview-src-list="srcList"
            >
            </el-image>
            <div class="upText">其他证件</div>
          </div>
        </div>
        <div class="title">代理商基本信息</div>
        <el-form-item label="法人姓名" prop="LegalPersonIName">
          <el-input
            v-model="basicInfoForm.LegalPersonIName"
            placeholder="请输入法人姓名"
            style="width: 250px"
          ></el-input>
        </el-form-item>
        <el-form-item label="法人身份证号" prop="LegalPersonIIDCard">
          <el-input
            v-model="basicInfoForm.LegalPersonIIDCard"
            placeholder="请输入法人身份证号"
            style="width: 250px"
          ></el-input>
        </el-form-item>
        <el-form-item label="法人身份证有效期" prop="LegalPersonlIDCardDate">
          <el-date-picker
            v-model="basicInfoForm.LegalPersonlIDCardDate"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="请选择法人身份证有效期"
            style="width: 250px"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="法人手机号码" prop="LegalPersonlPhone">
          <el-input
            v-model="basicInfoForm.LegalPersonlPhone"
            placeholder="请输入法人手机号码"
            style="width: 250px"
          ></el-input>
        </el-form-item>
        <el-form-item label="企业名称" prop="EnterpriseFullName">
          <el-input
            v-model="basicInfoForm.EnterpriseFullName"
            placeholder="请输入企业名称"
            style="width: 250px"
          ></el-input>
        </el-form-item>
        <el-form-item label="企业所在省市区" prop="provinces">
          <el-input
            v-model="basicInfoForm.provinces"
            placeholder="请输入企业所在省市区"
            style="width: 250px"
          ></el-input>
        </el-form-item>
        <el-form-item label="企业所在道路门牌" prop="Road">
          <el-input
            v-model="basicInfoForm.Road"
            placeholder="请输入企业所在道路门牌"
            style="width: 250px"
          ></el-input>
        </el-form-item>
        <el-form-item label="企业行业分类" prop="tcDdat">
          <el-input
            v-model="basicInfoForm.tcDdat"
            placeholder="请输入企业行业分类"
            style="width: 250px"
          ></el-input>
        </el-form-item>
        <el-form-item label="统一社会信用代码" prop="EnterpriseCreditCode">
          <el-input
            v-model="basicInfoForm.EnterpriseCreditCode"
            placeholder="请输入统一社会信用代码"
            style="width: 250px"
          ></el-input>
        </el-form-item>
        <el-form-item label="营业执照注册日期" prop="EnterpriseRegisterDate">
          <el-date-picker
            v-model="basicInfoForm.EnterpriseRegisterDate"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="请选择营业执照注册日期"
            style="width: 250px"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="营业执照住所" prop="EnterpriseAddress">
          <el-input
            v-model="basicInfoForm.EnterpriseAddress"
            placeholder="请输入营业执照住所"
            style="width: 250px"
          ></el-input>
        </el-form-item>
        <el-form-item label="营业执照有效日期" prop="EnterpriseEffectiveDate">
          <el-date-picker
            v-model="basicInfoForm.EnterpriseEffectiveDate"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="请选择营业执照有效日期"
            style="width: 250px"
          >
          </el-date-picker>
        </el-form-item>
        <div class="title">代理商发票抬头</div>
        <el-form-item label="发票抬头企业全名" prop="invoiceTitleName">
          <el-input
            v-model="vehicleForm.InvoiceTitleName"
            style="width: 250px"
          ></el-input>
        </el-form-item>
        <el-form-item label="纳税人识别号" prop="invoiceTitleCreditCode">
          <el-input
            v-model="vehicleForm.InvoiceTitleCreditCode"
            style="width: 250px"
          ></el-input>
        </el-form-item>
        <el-form-item label="银行账户" prop="invoiceTitleBankerNumber">
          <el-input
            v-model="vehicleForm.InvoiceTitleBankerNumber"
            style="width: 250px"
          ></el-input>
        </el-form-item>
        <el-form-item label="银行账户开户行" prop="invoiceTitleBankerName">
          <el-input
            v-model="vehicleForm.InvoiceTitleBankerName"
            style="width: 250px"
          ></el-input>
        </el-form-item>
        <el-form-item label="企业联系地址" prop="invoiceTitleAddress">
          <el-input
            v-model="vehicleForm.InvoiceTitleAddress"
            style="width: 250px"
          ></el-input>
        </el-form-item>
        <el-form-item label="企业联系电话" prop="invoiceTitlePhone">
          <el-input
            v-model="vehicleForm.InvoiceTitlePhone"
            style="width: 250px"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="topWrap">
        <el-button size="medium" @click="routerBack()" icon="el-icon-arrow-left"
          >返回</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { GetenterPriseInfo } from "@/api/auditInfo/auditOwner/index";
export default {
  data() {
    return {
      // 基本信息
      basicInfoForm: "",
      // 证件信息
      confirmInfo: "",
      // 抬头信息
      vehicleForm: "",
      UserID: "",
      // 图片放大预览
      srcList: [],
      // 其他证件
      eInfoEx: "",
      // tab状态
      urlType: "",
      pageIndex: 1,
    };
  },
  created() {
    this.pageIndex = this.$route.query.pageIndex
      ? this.$route.query.pageIndex
      : 1;
    this.UserID = this.$route.query.UserID;
    this.urlType = this.$route.query.urlType;
    // 查询货主/服务商信息
    this.GetenterPriseInfo(this.$route.query.UserID);
  },
  methods: {
    // 返回上一页
    routerBack() {
      this.$router.push({
        path: "/platform/alluser/index",
        query: {
          urlType: this.urlType,
          pageIndex: this.pageIndex,
        },
      });
    },
    // 查询货主/服务商信息
    GetenterPriseInfo(UserID) {
      GetenterPriseInfo({ UserID }).then((res) => {
        if (res.baseInfo) {
          this.basicInfoForm = res.baseInfo;
          if (res.baseInfo.Province) {
            this.basicInfoForm.provinces = `${res.baseInfo.Province} / ${res.baseInfo.City} / ${res.baseInfo.County}`;
          }
          if (res.baseInfo.TCKind) {
            this.basicInfoForm.tcDdat = `${res.baseInfo.TCKind} / ${res.baseInfo.TCBig}`;
          }
        }
        if (res.confirmInfo) {
          this.confirmInfo = res.confirmInfo;
          // 用于大图展示
          if (res.confirmInfo.LegalPersonIDCardOnURL) {
            this.srcList.push(res.confirmInfo.LegalPersonIDCardOnURL);
          }
          if (res.confirmInfo.LegalPersonIDCardBackURL) {
            this.srcList.push(res.confirmInfo.LegalPersonIDCardBackURL);
          }
          if (res.confirmInfo.BusinessLicenseURL) {
            this.srcList.push(res.confirmInfo.BusinessLicenseURL);
          }
          if (res.confirmInfo.RTBusinessLicenseURL) {
            this.srcList.push(res.confirmInfo.RTBusinessLicenseURL);
          }
          if (res.confirmInfo.BankCardUrl) {
            this.srcList.push(res.confirmInfo.BankCardUrl);
          }

          if (res.confirmInfo.OthersUrl) {
            this.srcList.push(res.confirmInfo.OthersUrl);
          }
        }
        if (res.titleInfo) {
          this.vehicleForm = res.titleInfo;
        }
        if (res.eInfoEx[0].ImageURL) {
          res.eInfoEx.forEach((item) => {
            this.srcList.push(item.ImageURL);
          });
          this.eInfoEx = res.eInfoEx;
        }
      });
    },
  },
};
</script>

<style lang="scss">
.wrap {
  .el-input.is-disabled .el-input__inner {
    color: #333 !important;
  }
}
</style>

<style lang="scss" scoped>
@import "../../../../assets/style/variable.scss";
.topWrap {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.img {
  width: 400px;
  height: 200px;
  border-radius: 10px;
}
.content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  .upBox {
    text-align: center;
    .upText {
      font-size: 14px;
      font-weight: bold;
      margin: 10px 0 0 0;
      color: #333333;
    }
  }
}
.title {
  color: $primary;
  position: relative;
  text-indent: 15px;
  font-size: 14px;
  border-bottom: 1px solid $borderGray;
  padding: 20px 0;
  margin-bottom: 20px;
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 3px;
    height: 14px;
    border-radius: 5px;
    background: $primary;
  }
}
</style>
